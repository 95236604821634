import { colors } from "./colors";

const defaults = {
  bg: "transparent",
  color: colors.text,
  fontFamily: "body",
  borderRadius: "sm",
  fontWeight: 600,
  fontSize: "base",
  p: 2,
  cursor: "pointer",
  transition: "background 0.15s ease-in-out",
  willTransition: "background",
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    background: colors.gray[1],
  },
  "&[disabled]": {
    opacity: "0.7",
    cursor: "not-allowed",
  },
};

const primary = {
  ...defaults,
  color: colors.white,
  bg: colors.primary,
  "&:hover": {
    background: colors.primaryHover,
  },
};

const secondary = {
  ...defaults,
  borderWidth: "1px",
  borderColor: colors.secondary,
  borderStyle: "solid",
  color: colors.secondary,
  transition: "all 0.25s linear",
  "&:hover": {
    borderColor: colors.secondaryHover,
    color: colors.secondaryHover,
  },
};

const tertiary = {
  ...defaults,
  color: colors.primary,
};

const transparent = {
  ...defaults,
  borderRadius: 0,
  border: "none",
  background: colors.transparent,
  "&:hover": {
    background: colors.transparent,
  },
};

export const buttons = {
  default: defaults,
  primary,
  secondary,
  tertiary,
  transparent,
};
