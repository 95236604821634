import { colors } from "./colors";

const defaults = {
  color: colors.text,
  fontFamily: "body",
  borderRadius: "sm",
  fontWeight: 400,
  fontSize: "base",
  lineHeight: 1.4,
};

const h1 = {
  ...defaults,
  fontSize: "3xl",
  fontWeight: 700,
  lineHeight: 1.2,
};

const h3 = {
  ...defaults,
  fontSize: "xl",
  fontWeight: 700,
  lineHeight: 1.2,
};

export const text = {
  default: defaults,
  h1,
  h3,
};
