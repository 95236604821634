import { tailwindPreset } from "./tailwind-preset";

export const colors = {
  ...tailwindPreset.colors,
  primary: tailwindPreset.colors.red[6],
  primaryHover: tailwindPreset.colors.red[7],
  secondary: tailwindPreset.colors.gray[6],
  secondaryHover: tailwindPreset.colors.gray[7],
  tertiary: tailwindPreset.colors.gray[6],
  tertiaryHover: tailwindPreset.colors.gray[7],

  brandBlue: "rgb(46, 170, 220)",
  brandBlueDark: "rgb(6, 156, 205)",
};
