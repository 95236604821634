import { colors } from "./colors";

const base = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "sm",
  fontSize: "xs",
  fontWeight: 600,
  lineHeight: 1,
  p: 1,
};

export const badges = {
  primary: {
    ...base,
    bg: "transparent",
    color: colors.primary,
    border: `2px solid ${colors.primary}`,
  },
  secondary: {
    ...base,
    bg: "transparent",
    color: colors.brandBlueDark,
    border: `2px solid ${colors.brandBlue}`,
  },
};
