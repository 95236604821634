import { colors } from "./colors";

const defaults = {
  borderRadius: "sm",
  color: colors.gray[9],
  border: `1px solid ${colors.gray[9]}`,
  fontSize: "sm",
  fontWeight: 300,
};

const info = {
  ...defaults,
  backgroundColor: "transparent",
  transition: "background-color ease-in-out 0.15s",
  "&:hover": {
    backgroundColor: colors.gray[1],
  },
};

export const messages = {
  info,
};
