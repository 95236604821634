import { colors } from "./colors";
import { radii } from "./radii";

const defaults = {
  display: "inline-block",
  color: "currentColor",
  textDecoration: "none",
  "&:active": {},
  "&:hover": {},
  "&:link": {},
  "&:visited": {},
  "&:focus": {
    outline: "1px dotted currentColor",
  },
};

const primary = {
  ...defaults,
  fontWeight: 600,
  color: colors.primary,
};

const secondary = {
  ...primary,
  fontWeight: 400,
  color: colors.text,
};

const tertiary = {
  ...defaults,
  color: colors.text,
  textDecoration: "underline",
  display: "inline",
};

const headerLink = {
  ...defaults,
  fontSize: "sm",
  fontWeight: 400,
  color: colors.secondary,
  "&:hover": {
    color: colors.secondaryHover,
  },
};

const headerLinkPrimary = {
  ...defaults,
  backgroundColor: colors.primary,
  color: colors.white,
  borderRadius: radii.sm,
  p: 2,
  fontSize: "sm",
  fontWeight: 600,
};

export const links = {
  default: defaults,
  primary,
  secondary,
  tertiary,
  headerLink,
  headerLinkPrimary,
};
