import { tailwindPreset } from "./tailwind-preset";

const defaultInput = {
  fontSize: "base",
  fontFamily: "body",
  "&::placeholder": {
    color: tailwindPreset.colors.gray[5],
  },
};

export const forms = {
  default: defaultInput,
  input: {
    ...defaultInput,
  },
  inputBorderless: {
    ...defaultInput,
    border: "none",
    "&:focus": {
      outline: "none",
    },
  },
};
