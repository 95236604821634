const space = {
	"1px": "1px",
	"2px": "2px",
	0: "0",
	1: "0.25rem",
	2: "0.5rem",
	3: "0.75rem",
	4: "1rem",
	5: "1.25rem",
	6: "1.5rem",
	8: "2rem",
	10: "2.5rem",
	12: "3rem",
	16: "4rem",
	20: "5rem",
	24: "6rem",
	32: "8rem",
	40: "10rem",
	48: "12rem",
	56: "14rem",
	64: "16rem"
};

// NEGATIVE SPACE!
space["-1px"] = `-${space["1px"]}`;
space["-2px"] = `-${space["2px"]}`;
space[-1] = `-${space[1]}`;
space[-2] = `-${space[2]}`;
space[-3] = `-${space[3]}`;
space[-4] = `-${space[4]}`;
space[-5] = `-${space[5]}`;
space[-6] = `-${space[6]}`;
space[-8] = `-${space[8]}`;
space[-10] = `-${space[10]}`;
space[-12] = `-${space[12]}`;
space[-16] = `-${space[16]}`;
space[-20] = `-${space[20]}`;
space[-24] = `-${space[24]}`;
space[-32] = `-${space[32]}`;
space[-40] = `-${space[40]}`;
space[-48] = `-${space[48]}`;
space[-56] = `-${space[56]}`;
space[-64] = `-${space[64]}`;

export { space };
