import { tailwindPreset } from "./tailwind-preset";

import { badges } from "./badges";
import { buttons } from "./buttons";
import { colors } from "./colors";
import { fonts } from "./fonts";
import { forms } from "./forms";
import { fontSizes } from "./font-sizes";
import { links } from "./links";
import { messages } from "./messages";
import { radii } from "./radii";
import { space } from "./space";
import { text } from "./text";

export const theme = {
  ...tailwindPreset,
  badges,
  buttons,
  colors,
  fontSizes,
  fonts,
  forms,
  links,
  messages,
  space,
  text,
  radii,
  shadows: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  borders: {
    none: "none",
    default: `1px solid ${tailwindPreset.colors.gray[3]}`,
    dotted: `1px dotted ${tailwindPreset.colors.gray[3]}`,
    "dotted-double": `2px dotted ${tailwindPreset.colors.gray[3]}`,
  },
  borderWidths: {
    0: 0,
    1: "1px",
    2: "2px",
    4: "4px",
    8: "8px",
  },
  styles: {
    root: {
      color: "#000000",
      fontFamily: "body",
      fontWeight: 300,
      fontSize: "xl",
      lineHeight: "normal",
    },
    a: links.tertiary,
    p: {
      color: "rgb(45, 55, 72)",
      fontSize: "lg",
      lineHeight: 1.4,
      margin: 0,
      ":not(:last-child)": {
        marginBottom: 2,
      },
    },
    h3: {
      fontSize: "lg",
      fontWeight: 600,
      marginTop: [5],
      marginBottom: [1],
    },
    ul: {
      margin: 0,
      paddingLeft: 6,
    },
    li: {
      lineHeight: 1.4,
      marginBottom: [2],
      fontSize: "lg",
    },
    blockquote: {
      color: colors.secondary,
      borderLeft: `3px solid ${colors.primary}aa`,
      paddingInlineStart: `2rem`,
      paddingInlineEnd: `1rem`,
      marginInlineStart: 0,
      marginInlineEnd: 0,
      fontSize: "lg",
      "> p": {
        color: colors.secondary,
      },
    },
    strong: {
      fontWeight: 600,
    },
  },
};
